







































import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'
import DefaultButton from '@/builder/sections/section/components/DefaultButton.vue'

export default mixins(Section).extend({
  name: 'Booking4',
  components: { DefaultButton },
  data () {
    return {
      date: new Date().toISOString().substr(0, 10),
      minDate: new Date().toISOString().substr(0, 10),
      menu: false,
      selectService: null
    }
  }
})
